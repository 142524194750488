.textColor {
    color:blueviolet;
}

.textSizeLocations {
    font-size:8px;
}

:global(a).linkColor {
    display: block;
    color:#4a4a4a;   
}

:global(a).linkColorButtom {
    color:#ffffff;   
}

:global(a:hover).HoverButtom {
    color: #ffffff!important;
    

  }

.iconInsta {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    height: 1.2rem;
    width: 1.2rem;
    fill: rgb(138 43 226);

}