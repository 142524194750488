.fill-window {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.test {
    min-width: 16.5rem;
    min-height: 13rem;
    max-width: 30rem;

}

.headerText {
    display: flex;
    font-size: 42px;
    line-height: 2.5rem;
    justify-content: center;
}

.headerTextMobile {
    display: flex;
    font-size: 20px;
    line-height: 1.5rem;
    justify-content: center;
}

.headerTextMobileThanks {
    display: flex;
    font-size: 21px;
    line-height: 1.5rem;
    justify-content: center;
}

.TextMobileThanks {
    display: flex;
    font-size: 18px;
    line-height: 1.5rem;
    justify-content: center;
}


.headerTextCall {
    display: flex;
    font-size: 25px;
    line-height: 3.5rem;
    justify-content: center;
}

.headerTextCallMobile {
    display: flex;
    font-size: 16px;
    margin-top: -0.8rem;
    line-height: 3.5rem;
    justify-content: center;
}

.thanks {
    margin-top: 10rem;
}

.thanksMobile {

    margin-top: 6rem;
}