.info {
    width: 20rem;
    height: 15rem;
    background-color: #f84e6d;
}

.boxSize {
    height: 100%;
}

  
:global(.box).test {
    min-width: 16.0rem;
    min-height: 15rem;
    max-width: 30rem;

}

.size {
    display: flex;
    width: 100%;
    height: 100%;
}

.fill-window {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}


.row {
    display: flex;

}

.enable-line-break {
    white-space: pre-wrap;
}

.orderBox {
    display: flex;
    align-content: space-between;
    justify-content: center;
    margin: 2rem;
}

.orderHeadline {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
}

.left > * {
    margin-right: 1.5rem;
  }
  
  .right > * {
    margin-left: 1.5rem;
  }
  
  .right {
    display: flex;
    align-items: center;
  }

  .iconSize {
      height: 20px;
      margin-right: 1rem;
  }