.imgPosition {
    display: block;
    position:absolute;
    width: 100%;
    height: 100%;
    top: 10;
    left: 0;
    object-fit:cover;
    filter: grayscale(100%) opacity(35%);
    mix-blend-mode: multiply;
}

.marginTop {
    margin-top: 10rem;
}


.fill-window {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.text {
    display: flex;
    margin-top: 10rem;
    line-height: 2.5rem;
}

.text2 {
    display: flex;
    align-items: flex-end;
    line-height: 2.5rem;
}

.text3 {
    display: flex;
    margin-top: 1rem;
    line-height: 1.5rem;
    width: 12rem;
    margin-left: 1rem;
}