.fill-window {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.imgPosition {
    display: block;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    
}
