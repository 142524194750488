.img {
    max-height: 300px;    
}

.boxSize {
    width: 30rem;
    height: 30rem;
}

.sbox {
    width: 20rem;
    height: 10rem;
}

:global(.box).test {
    min-height: 21rem;
    max-width: 30rem;
}

:global(.box).test2 {
    max-height: 25rem;
    
}

.size {
    display: flex;
    width: 100%;
    height: 100%;
}

.fill-window {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;

}

.row {
    display: flex;

}

.enable-line-break {
    white-space: pre-wrap;
}

.orderBox {
    display: flex;
    align-content: space-between;
    justify-content: center;
    margin: 2rem;
    align-items: center;
    justify-items: center;
    
}

.orderHeadline {
    display: flex;
    margin-top: 10rem;
    justify-content: center;
}

.left > * {
    margin-right: 1.5rem;
  }
  
  .right > * {
    margin-left: 1.5rem;
  }
  
  .right {
    display: flex;
    align-items: center;
  }

  .iconSize {
      height: 20px;
      margin-right: 1rem;
  }