.fill-window {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.img {
    display: flex;
    object-fit: contain;
    margin-left: 8rem;
}

:global(.box).test {
    width: 293px;
    margin-left: 41rem;
    margin-top: 1rem;

}

:global(.box).test1 {
    width: 293px;
    margin-left: 33rem;

}

:global(.message).testText {
    width: 20rem;
}

:global(.message).testText2 {
    width: 20rem;
    margin-right: 20rem;

}

:global(.box).size {
    display: flex;
    height: 293px;
    width: 293px;
}

.imgMobile {
    display: flex;
    height: 293px;
    width: 293px;
}

.text {
    display: flex;
    line-height: 2.0rem;    
}

.textCenter {
    display: flex;
    justify-content: center; 
    margin-top: 10rem;    
}

.row {
    display: flex;
    flex-direction: column;
}

.rowCenter {
    display: flex;
}

.headerText {
    display: flex;
    font-size: 42px;
}

.headerTextMobile {
    display: flex;
    font-size: 32px;
    line-height: 2.5rem;
}

.prisText {
    font-size: 32px;
}


.listText {
    margin: 2rem;
}

